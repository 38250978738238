<template>
  <div class="aboutPrinciples">
    <h2 class="heading1">
      About Principles
      <hr class="gradientLine" />
    </h2>
    <div
      class="flex w-full mb-8"
      style="
        display: grid;
        grid-template-columns: 2rem 1fr;
        grid-column-gap: 1rem;
      "
    >
      <h4 class="heading3 pt-4" style="color: #f82553">1.</h4>
      <img src="../../../../public/qrec_scheme-20-15.png" alt="" />
    </div>

    <p class="my-4">
      To ensure the public has a better understanding of quantum technologies
      and their potential benefits, we aim to increase awareness and knowledge
      on this subject. By doing so, people can gain a general understanding of
      how quantum technologies impact their daily lives, which will allow them
      to make informed decisions when considering investments in this rapidly
      evolving industry.
    </p>

    <div
      class="flex w-full mb-8"
      style="
        display: grid;
        grid-template-columns: 2rem 1fr;
        grid-column-gap: 1rem;
      "
    >
      <h4 class="heading3" style="color: #6434e9">2.</h4>
      <img
        src="../../../../public/qrec_scheme-20-19.png"
        height="548"
        width="1587"
      />
    </div>
    <p class="my-4">
      In every STEM field, it's essential to address the issues of diversity,
      equity, and inclusion. Without a supportive framework, many people may opt
      to leave the quantum path and pursue other avenues. However, by
      implementing policies and practices that address these concerns, we can
      create an inclusive quantum community that is open and welcoming to all.
    </p>

    <div
      class="flex w-full mb-8"
      style="
        display: grid;
        grid-template-columns: 2rem 1fr;
        grid-column-gap: 1rem;
      "
    >
      <h4 class="heading3" style="color: #2c7ce5">3.</h4>

      <img
        src="../../../../public/qrec_scheme-20-20.png"
        height="548"
        width="1587"
      />
    </div>
    <p class="my-4">
      By establishing a shared knowledge space that bridges schools,
      universities, industry, and research, we can ensure transparency in job
      requirements, clearly defined paths to career progression, transparent
      learning outcomes and curricula, and better alignment between education
      and work. This will ultimately result in more clearly defined and
      optimised education and career paths, allowing for more effective planning
      and preparation.
    </p>

    <div
      class="flex w-full mb-8"
      style="
        display: grid;
        grid-template-columns: 2rem 1fr;
        grid-column-gap: 1rem;
      "
    >
      <h4 class="heading3" style="color: #49cc5c">4.</h4>
      <img
        src="../../../../public/qrec_scheme-20-16.png"
        height="548"
        width="1587"
      />
    </div>
    <p class="my-4">
      Ensuring organisational representation throughout the quantum pipeline is
      crucial as it facilitates communication and alignment between various
      stages of the quantum path. This representation allows for the gathering
      of feedback and recommendations to be provided to the Quantum Flagship,
      the European Commission, and other decision-making bodies.
    </p>

    <div
      class="flex w-full mb-8"
      style="
        display: grid;
        grid-template-columns: 2rem 1fr;
        grid-column-gap: 1rem;
      "
    >
      <h4 class="heading3" style="color: #edb407">5.</h4>
      <img
        src="../../../../public/qrec_scheme-17.png"
        height="547"
        width="1587"
      />
    </div>
    <p class="my-4">
      Prioritising teaching innovation is crucial for developing up-to-date
      teaching materials, effective pedagogical strategies, and flexible course
      and program structures in schools and universities. Emphasising innovative
      teaching approaches ultimately results in higher-quality education,
      producing more skilled graduates, and providing more effective training
      opportunities.
    </p>

    <div
      class="flex w-full mb-8"
      style="
        display: grid;
        grid-template-columns: 2rem 1fr;
        grid-column-gap: 1rem;
      "
    >
      <h4 class="heading3" style="color: #fb6640">6.</h4>
      <img
        src="../../../../public/qrec_scheme-18.png"
        height="547"
        width="1587"
      />
    </div>
    <p class="my-4">
      Widening access to quantum education and upskilling is vital, as it
      enables greater accessibility for the public, schools, and universities.
      Furthermore, it provides more specialised education and upskilling
      opportunities that are essential for meeting the demands of the growing
      quantum industry. Ultimately, the goal is to break down barriers to
      quantum competencies, ensuring that everyone who wishes to access them can
      do so.
    </p>
  </div>
</template>

<script>
export default {
  name: "AboutPrinciples.vue",
};
</script>

<style scoped></style>
